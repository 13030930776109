import { AppProps, storage } from 'react-commons';
import { useEffectOnce } from 'react-commons';
import { authActions, authActionSets, useAuthStore } from '@/lib/drupal/stores/auth';
import { themeActions, useThemeStore } from '@/lib/stores/theme';
import UAParser from 'ua-parser-js';
import Cookies from 'js-cookie';

export function useDrupalAuth (onVerify?: AppProps['onAuthVerify'], onError?: AppProps['onAuthError']) {
  const [ authState, authDispatch, authExecute ] = useAuthStore();
  const [ themeState, themeDispatch ] = useThemeStore();

  useEffectOnce(
    () => {
      (async () => {
        if (authState.auth || storage.getLocal('auth')?.auth) {
          if (authState.auth) {
            await authExecute(authActionSets.verify({ 
              onError,
            }));
            if (onVerify) onVerify();
          }
        } else {
          const layoutCookie = Cookies.get('sw_result_layout') as any;
          const layout = layoutCookie || 'modern';
          themeDispatch(themeActions.setLayout(layout));
          authDispatch(authActions.setReady(true));
        }
      })();
    }, 
    () => {
      if (!authState.auth && !authState.user) {
        return true;
      }
    },
    [ authState.auth ]
  );
}
