import axios from '@/lib/drupal/api/axiosInstance';
import { SearchCriteria } from '@/lib/hooks/useGamesByCriteria';
import { cleanString } from '@/lib/util/cleanString';
import { decodeHtmlEntities } from '@/lib/util/decodeHtmlEntities';
import { isTruthy } from '@/lib/util/isTruthy';
import { mockGet } from '@/lib/util/mockRequest';
import { pagingQueryString } from '@/lib/util/pagingQueryString';
import { getZonedDate } from '@/lib/util/zonedTime';
import { AxiosResponse } from 'axios';
import { logErrorEvent } from 'react-commons';
import { DailySearchConfig } from '@/lib/hooks/useDailyGamesByCriteria';
import { PagerData, formatPagerData } from '@/lib/util/pager';

export type DailyGameColor = 
  'white' |
  'yellowGreen' | 
  'halloweenOrange' | 
  'persianGreen' | 
  'maroon' | 
  'purple' | 
  'lightBlue' | 
  'yellow' | 
  'brown' | 
  'red' |
  'goldenrod';

export interface DrupalGameData {
  id: string
  uid: string
  nid: string
  view_node: string
  title: string
  field_keyword: string
  field_keyword_1?: string
  field_primary_genre: string
  field_keyword_regular?: string
  field_keyword_small?: string
  field_keyword_large?: string
  field_keyword_xl?: string
  dynamic_feature?: string
  field_dynamic_feature?: string
  daycount: string
  field_first_release_date: string
  field_aggregate_game_rating: string
  field_description?: string
  field_short_description: string
  field_medium_description?: string
  field_aggregate_rating_count: string
  field_mobile_image?: string
  field_mobile_icon?: string
  field_mobile_title_short?: string
  field_mobile_description_short?: string
  field_highlight_color?: DailyGameColor
  sw_game_download_url?: string
  sw_paidgame_buy_url?: string
  sw_paidgame_price?: string
  published_at?: string
  sw_urls?: [
    {
      sw_paidgame_buy_url: string
      sw_game_download_url: string
    }
  ]
}

export interface DrupalFullGameData {
  nid: string
  type: string
  created: string
  published_at: string
  author: {
    uid: number
    name: string
    url: string
    picture: string
  }
  title: string
  view_node: string
  field_description: string
  field_medium_description: string
  field_short_description: string
  field_primary_genre_export: {
    id: number
    title: string
    url: string
  } | null
  field_quaternary_genre_export: {
    id: number
    title: string
    url: string
  } | null
  field_quinary_genre_export: {
    id: number
    title: string
    url: string
  } | null
  field_secondary_genre_export: {
    id: number
    title: string
    url: string
  } | null
  field_tertiary_genre_export: {
    id: number
    title: string
    url: string
  } | null
  field_revenue_share_advertising_: string
  field_revenue_share_subscription: string
  field_exclude_from_revshare_repo: string
  field_revenue_share_cost_: string
  field_game_instructions: string
  field_game_tips_and_tricks: string
  field_minumum_sys_requirement: string
  field_technical_issue_copy: string
  field_game_credits: string
  field_frontpage_sort: string
  field_first_release_date: string
  field_keyword: string
  field_product_sku: string
  field_display_height: string
  field_display_width: string
  field_shockwave_game_id: string
  field_old_url: string
  field_daily_bonus_reference_export: null | any
  field_game_markup_: string
  field_keyword_small: string
  field_keyword_regular: string
  field_keyword_large: string
  field_keyword_xl: string
  field_keyholeheader: string
  field_instructions_image: string
  dynamic_feature?: string
  field_dynamic_feature: string
  field_billboard_two: string
  field_billboard_three: string
  field_game_files_directory_path: string
  field_is_this_a_daily_game_: string
  field_does_this_game_have_a_bonu: string
  field_is_this_a_bonus_daily_game: string
  field_does_this_game_have_a_high: string
  field_is_this_game_daily_jigsaw_: string
  field_should_archive_display_hid: string
  field_does_this_game_have_thumbn: string
  field_is_this_game_mobile_: string
  field_show_huge_advert_: string
  field_is_chat_enabled_: string
  field_is_this_a_calendar_game_: string
  field_is_shockwave_exclusive_: string
  field_has_downloadable_purchase_: string
  field_has_downloadable_trial_ver: string
  field_has_online_version_: string
  field_aggregate_game_rating: string
  field_aggregate_rating_count: string
  field_calendar_only_iframe_sourc: string
  field_game_display_markup_fullsc: string
  field_display_markup_premium: string
  field_game_mkup_full_scripts: string
  field_custom_game_button_one: string
  field_custom_button_action_one: string
  field_custom_game_button_action_: string
  field_custom_game_button_two: string
  field_custom_button_action_three: string
  field_custom_game_button_three: string
  field_dyn_button_one: string
  field_dyn_button_one_url: string
  field_dyn_button_two: string
  field_dyn_button_two_url: string
  field_dynld_url_one: string
  field_dynld_url_two: string
  field_dyn_lead_backup_image: string
  field_dyn_lead_backup_image_two: string
  field_is_this_game_create_and_sh: string
  field_is_this_a_free_club_game_: string
  field_is_club_exclusive_: string
  field_is_this_game_virtual_goods: string
  field_is_this_a_token_game_: string
  field_is_this_a_trophy_game_: string
  field_is_this_game_service_manag: string
  field_is_the_game_multiplayer_: string
  field_is_this_an_advert_game_: string
  field_is_game_aws_active_: string
  daycount: string
  field_archive_start_date: string
  field_archive_end_date: string
  field_archive_frquency: string
  field_archive_value: ArchiveDisplayType
  field_mobile_image?: string
  field_mobile_icon?: string
  sw_game_download_url: string
  sw_paidgame_buy_url: string
  sw_paidgame_price: string
  field_mobile_body?: string
  field_mobile_game_url?: string
  field_archive_frequency: 'weekly' | 'daily'
  field_suggest_a_random_game: '1' | '0'
  field_alternate_game_suggestion: DrupalGameData,
  random_game_suggestion_endpoint: string
  field_game_url?: string
  field_walkthroughs?: string
  field_modern_game_url?: string
  field_highlight_color?: DailyGameColor
  field_swag_score_type?: string
  field_swag_daily_level_key?: string
  field_use_landing_page?: boolean
}

export const mockGameSchema = {
  id: '$id',
  view_node: '$slug()',
  title: 'Mock Game',
  field_keyword: 'daily-sort',
  field_primary_genre: '',
  field_keyword_regular: '',
  field_keyword_small: '',
  field_keyword_large: '',
  field_dynamic_feature: '',
  daycount: '$roundedRange(1, 1000)',
  field_first_release_date: 'January 1, 1970',
  field_aggregate_game_rating: '$numberRange(1, 5)',
  field_short_description: '$text(100)',
  field_aggregate_rating_count: '$numberRange(1, 1000)',
};

export interface GameData {
  uid: string
  href: string
  title: string
  rating: string
  numPlays: number
  keyword: string
  lastUpdated?: number
  thumbnailImg: string
  thumbnailImgTall: string
  thumbnailImgSmall: string
  thumbnailImgXl?: string
  featuredImg?: string
}

export interface FeaturedGameData extends GameData {
  description: string
  featuredImg: string
}

export interface ExtendedGameData extends GameData {
  description: string
  descriptionMedium?: string
  descriptionShort?: string
  numRatings: number
  publishedAt: number
  mobileThumbnail: string
  mobileIcon: string
  downloadUrl?: string
  paidgameBuyUrl?: string
  paidgamePrice?: string
  featuredImg?: string
  highlightColor?: DailyGameColor
}

export interface FullGameData extends ExtendedGameData {
  pageLayout: GamePageLayout
  hasLeaderboards: boolean
  thumbnailImgXl: string
  featuredImg: string
  billboardImg: string[]
  isDailyGame: boolean
  isDownloadGame: boolean
  hasTrial: boolean
  hasPurchase: boolean
  archive?: {
    startDate: string | null
    endDate: string | null
    frequency: 'daily' | 'weekly'
    display: ArchiveDisplayType | null
    contentUrl: string
  }
  instructions: string
  requirements: string
  credits: string
  genres: string[]
  embed: {
    width: number
    height: number
    src: string
    mobileSrc?: string
    modernSrc?: string
    allowFullscreen: boolean
    isFlash: boolean
    originalSrc: string
  }
  downloadUrl?: string
  paidgameBuyUrl?: string
  paidgamePrice?: string
  productSku?: string
  textDescription: string
  mobileDescription: string
  shortDescription: string
  isSwuExclusive: boolean
  isBonusGame: boolean
  isChallengeJigsaw: boolean
  bonusGameUrl?: string
  bonusGameFrequency: 'daily' | 'weekly' | ''
  displayAlternateSuggestion: boolean
  randomGameSuggestionUrl?: string
  alternateSuggestionGame?: ExtendedGameData
  walkthrough?: string
  dailyLevelKey?: string
  hasChatEnabled: boolean
  showLandingPage: boolean
}

export type ThumbnailSize = 'regular' | 'small' | 'tall' | 'xl'

export type GamePageLayout = 'online' | 'daily' | 'jigsaw' | 'download'

export type ArchiveDisplayType = 'list' | 'picture' | 'none'

export interface DailyGameUpsell {
  field_link: string
  field_mobile_icon: string
  field_highlight_color: DailyGameColor
  nid: string
  field_upsell_type: 'default' | 'jigsaw'
} 

export function getThumbnail (src?: string, size?: ThumbnailSize) {
  if (src) {
    return process.env.NEXT_PUBLIC_IMAGE_DOMAIN + src;
  }

  return `/images/gameTilePlaceholder${size.charAt(0).toUpperCase()}${size.slice(1)}.png`;
}

function getMobileThumbnail (src?: string) {
  if (src) {
    return process.env.NEXT_PUBLIC_IMAGE_DOMAIN + src;
  }

  return '/images/mobileThumbnailPlaceholder.svg';
}

function getFeaturedImg (src?: string) {
  if (src) {
    return process.env.NEXT_PUBLIC_IMAGE_DOMAIN + src;
  }

  return '/images/featuredPlaceholder.png';
}

function getBillboardThumbnail (src?: string) {
  if (src) {
    return process.env.NEXT_PUBLIC_IMAGE_DOMAIN + src;
  }

  return '/images/billboardThumbnailPlaceholder.png';
}

export type DailyGameFields = {
  year: string
  month: string
  day: string
}

export const DAILY_GAME_QUERY = 'date=[custom:daily-game-year]/[custom:daily-game-month]/[custom:daily-game-day]';

export function getDailyGameSrc (src: string, fields?: DailyGameFields) {
  const date = getZonedDate();

  if (fields?.year) src = src.replace('[custom:daily-game-year]', fields.year);
  else src = src.replace('[custom:daily-game-year]', date.getFullYear().toString().slice(-2));

  if (fields?.month) src = src.replace('[custom:daily-game-month]', fields.month.toString().padStart(2, '0'));
  else src = src.replace('[custom:daily-game-month]', (date.getMonth() + 1).toString().padStart(2, '0'));

  if (fields?.day) src = src.replace('[custom:daily-game-day]', fields.day.toString().padStart(2, '0'));
  else src = src.replace('[custom:daily-game-day]', date.getDate().toString().padStart(2, '0'));

  return src;
}

export function getTomorrowsGameSrc (src: string) {
  const date = getZonedDate();
  date.setDate(date.getDate() + 1);

  return getDailyGameSrc(src, {
    year: date.getFullYear().toString().slice(-2),
    month: (date.getMonth() + 1).toString().padStart(2, '0'),
    day: date.getDate().toString().padStart(2, '0'),
  });
}

function fieldsFromEmbed (displayWidth: string, displayHeight: string, embedCode: string) {
  const src = embedCode.match(/src=["']*(https?:\/\/[^"']*)["']*/)?.[ 1 ] || embedCode;
  const allowFullscreen = 
    embedCode.includes('iframe') 
      ? embedCode.includes('allowfullscreen')
      : true;

  const width = displayWidth
    ? parseInt(displayWidth)
    : parseInt(embedCode.match(/width=["']*([0-9]+)[pxemr"']*/)?.[ 1 ] || '0');

  const height = displayHeight
    ? parseInt(displayHeight)
    : parseInt(embedCode.match(/height=["']*([0-9]+)[pxemr"']*/)?.[ 1 ] || '0');

  const isFlash = src.endsWith('.swf');

  return {
    src,
    width,
    height,
    allowFullscreen,
    isFlash,
    originalSrc: embedCode,
  };
}

export const GameTags = [
  'game_tag_addictive',
  'game_tag_challenging',
  'game_tag_cute',
  'game_tag_easy',
  'game_tag_funny',
  'game_tag_great_graphics',
  'game_tag_long',
  'game_tag_mindless',
  'game_tag_quick',
  'game_tag_serious',
  'game_tag_tedious',
  'game_tag_unique',
];

export type GameTag = typeof GameTags[ number ];

export function labelForGameTag (tag: GameTag) {
  return tag
    .replace('game_tag_', '')
    .replace(/_/g, ' ')
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
}

export function mobileHref (href: string) {
  return href.replace('gamelanding', 'gamelanding/mobile');
}

export interface AdminFlags {
  basic_node_test_flag: number
  coming_soon_flag: number
  daily_carousel: number
  daily_game_featured: number
  download_carousel: number
  download_featured: number
  download_top_games: number
  exclusive_partner: number
  front_page_carousel: number
  front_page_featured: number
  front_top_games: number
  nn_cent_sale: number
  online_carousel: number
  online_featured: number
  online_top_games: number
  random_game_assignment: number
  selected_feature_legacy_: number
  shockwave_self_extra_sidebar_pro: number
  taxonomy_carousel: number
  taxonomy_featured: number
  taxonomy_top_games: number
  unpublish_games: number
}

export default class GamesModel {
  static transform (rawData: DrupalGameData): GameData {
    const lastUpdated = getZonedDate(rawData.field_first_release_date || rawData.published_at).getTime();

    return {
      uid: (rawData.id || rawData.uid || rawData.nid).toString(),
      href: rawData.view_node || '/',
      title: decodeHtmlEntities(rawData.title),
      rating: rawData.field_aggregate_game_rating || '0.0',
      numPlays: parseInt(rawData.daycount) || 0,
      keyword: rawData.field_keyword || rawData.field_keyword_1 || '',
      thumbnailImg: getThumbnail(rawData.field_keyword_regular, 'regular'),
      thumbnailImgTall: getThumbnail(rawData.field_keyword_large, 'tall'),
      thumbnailImgSmall: getThumbnail(rawData.field_keyword_small, 'small'),
      featuredImg: getFeaturedImg(rawData.dynamic_feature || rawData.field_dynamic_feature),
      lastUpdated,
    };
  }

  static transformFeatured (rawData: DrupalGameData): FeaturedGameData {
    return {
      ...GamesModel.transform(rawData),
      description: decodeHtmlEntities(rawData.field_short_description || ''),
      featuredImg: getFeaturedImg(rawData.dynamic_feature || rawData.field_dynamic_feature),
    };
  }

  static transformExtended (rawData: DrupalGameData): ExtendedGameData {
    const paidgameBuyUrl = (
      Array.isArray(rawData.sw_urls)
        ? (rawData.sw_urls[ 0 ]?.sw_paidgame_buy_url || '/')
        : (rawData.sw_paidgame_buy_url || '/')
    ) || '/';

    const downloadUrl = (
      Array.isArray(rawData.sw_urls)
        ? (rawData.sw_urls[ 0 ]?.sw_game_download_url)
        : (rawData.sw_game_download_url || '/')
    ) || '/';

    const publishedAt = getZonedDate(rawData.field_first_release_date || rawData.published_at).getTime();

    return {
      ...GamesModel.transform(rawData),
      description: rawData.field_description || rawData.field_medium_description || rawData.field_short_description || '',
      descriptionMedium: rawData.field_medium_description || rawData.field_short_description || '',
      descriptionShort: rawData.field_mobile_description_short || rawData.field_short_description || '',
      numRatings: parseInt(rawData.field_aggregate_rating_count || '0'),
      publishedAt,
      mobileThumbnail: getMobileThumbnail(rawData.field_mobile_image),
      mobileIcon: getMobileThumbnail(rawData.field_mobile_icon),
      downloadUrl,
      paidgameBuyUrl,
      paidgamePrice: rawData.sw_paidgame_price || '',
      thumbnailImgXl: getThumbnail(rawData.field_keyword_xl, 'xl'),
      featuredImg: getFeaturedImg(rawData.field_dynamic_feature),
      highlightColor: rawData.field_highlight_color || 'white'
    };
  }

  static transformMobile (rawData: DrupalGameData): ExtendedGameData {
    const game = GamesModel.transformExtended(rawData);

    game.title = rawData.field_mobile_title_short || game.title;
    game.description = rawData.field_mobile_description_short || game.description;

    return game;
  }

  static transformFull (rawData: DrupalFullGameData): FullGameData {
    const genres = [
      'field_primary_genre_export',
      'field_secondary_genre_export',
      'field_tertiary_genre_export',
      'field_quaternary_genre_export',
      'field_quinary_genre_export',
    ].reduce((genreIds, key) => {
      const field = rawData?.[ key ] as { id: number };
      if (field) genreIds.push(field.id.toString());
      return genreIds;
    }, []);

    const isJigsawGame = () => {
      return (
        rawData.view_node.includes('jigsawpuzzles') ||
        rawData.view_node.includes('bonusjigsaws') ||
        rawData.view_node.includes('jigsawpuzzlechallenge')
      );
    };

    const pageLayout = isJigsawGame()
      ? 'jigsaw'
      : (() => {
        switch (rawData.type) {
        case 'Daily Game': return 'daily';
        case 'Download Game': return 'download';
        case 'Paid Game': return 'download';
        default: return 'online';
        }
      })();

    const archiveValue = (rawData.field_archive_value || '')
      .trim()
      .toLowerCase();

    const hasArchive = (
      (
        isTruthy(rawData.field_does_this_game_have_thumbn) ||
        !!rawData.field_archive_start_date
      ) && 
      !!archiveValue && 
      archiveValue !== 'none' && 
      archiveValue !== 'null'
    );

    const archive: FullGameData['archive'] = hasArchive
      ? ({
        startDate: cleanString((rawData.field_archive_start_date || '').trim()) || null,
        endDate: cleanString((rawData.field_archive_end_date || '').trim()) || null,
        frequency: (rawData.field_archive_frequency).toLowerCase() as ('daily' | 'weekly'),
        display: (archiveValue as ArchiveDisplayType) || null,
        contentUrl: rawData.field_game_files_directory_path || ''
      })
      : null;

    const description = decodeHtmlEntities(rawData.field_description || '');
    const textDescription = description.replace(/<[^>]*>?/gm, '');
    const mobileDescription = rawData.field_mobile_body || '';
    const shortDescription = decodeHtmlEntities(rawData.field_short_description || textDescription);

    const mobileSrc = decodeHtmlEntities(
      decodeURIComponent(
        decodeURIComponent(
          rawData.field_mobile_game_url || ''
        )
      )
    );

    const bonusGameFrequency = (() => {
      const title = rawData.field_daily_bonus_reference_export?.title || '';
      if (!title) return '';
      const isWeekly = title.toLowerCase().includes('week');
      return isWeekly ? 'weekly' : 'daily';
    })();

    const displayAlternateSuggestion =
      !!rawData.field_alternate_game_suggestion ||
      (
        isTruthy(rawData.field_suggest_a_random_game) &&
        !!rawData.random_game_suggestion_endpoint
      );

    return {
      uid: rawData.nid,
      href: rawData.view_node,
      title: decodeHtmlEntities(rawData.title),
      rating: rawData.field_aggregate_game_rating || '0.0',
      numPlays: parseInt(rawData.daycount) || 0,
      keyword: rawData.field_keyword,
      thumbnailImg: getThumbnail(rawData.field_keyword_regular, 'regular'),
      thumbnailImgTall: getThumbnail(rawData.field_keyword_large, 'tall'),
      thumbnailImgSmall: getThumbnail(rawData.field_keyword_small, 'small'),
      description,
      textDescription,
      descriptionShort: rawData.field_short_description || '',
      numRatings: parseInt(rawData.field_aggregate_rating_count || '0'),
      publishedAt: (getZonedDate(rawData.field_first_release_date)).getTime(),
      pageLayout,
      hasLeaderboards: isTruthy(rawData.field_does_this_game_have_a_high),
      archive,
      thumbnailImgXl: getThumbnail(rawData.field_keyword_xl, 'xl'),
      featuredImg: getFeaturedImg(rawData.dynamic_feature || rawData.field_dynamic_feature),
      billboardImg: [
        getBillboardThumbnail(rawData.field_billboard_two),
        getBillboardThumbnail(rawData.field_billboard_three),
      ],
      isDailyGame: isTruthy(rawData.field_is_this_a_daily_game_),
      isDownloadGame: pageLayout === 'download',
      hasTrial: isTruthy(rawData.field_has_downloadable_trial_ver),
      hasPurchase: isTruthy(rawData.field_has_downloadable_purchase_),
      instructions: rawData.field_game_instructions || '',
      requirements: rawData.field_minumum_sys_requirement || '',
      credits: rawData.field_game_credits || '',
      genres,
      embed: {
        ...fieldsFromEmbed(
          rawData.field_display_width, 
          rawData.field_display_height, 
          rawData.field_game_url || rawData.field_game_markup_ || '',
        ),
        mobileSrc,
        modernSrc: rawData.field_modern_game_url || '',
      },
      productSku: rawData.field_product_sku || '',
      downloadUrl: rawData.sw_game_download_url || '/',
      paidgameBuyUrl: rawData.sw_paidgame_buy_url || '/',
      paidgamePrice: rawData.sw_paidgame_price || '',
      mobileThumbnail: getMobileThumbnail(rawData.field_mobile_image),
      mobileIcon: getMobileThumbnail(rawData.field_mobile_icon),
      mobileDescription,
      shortDescription,
      isSwuExclusive: isTruthy(rawData.field_is_shockwave_exclusive_),
      isBonusGame: isTruthy(rawData.field_is_this_a_bonus_daily_game),
      isChallengeJigsaw: rawData.view_node.includes('jigsawpuzzlechallenge'),
      bonusGameUrl: rawData.field_daily_bonus_reference_export?.url || '',
      bonusGameFrequency,
      displayAlternateSuggestion,
      randomGameSuggestionUrl: rawData.random_game_suggestion_endpoint,
      alternateSuggestionGame: !!rawData.field_alternate_game_suggestion
        ? GamesModel.transformExtended(rawData.field_alternate_game_suggestion)
        : null,
      walkthrough: rawData.field_walkthroughs || '',
      highlightColor: rawData.field_highlight_color || 'white',
      dailyLevelKey: rawData.field_swag_daily_level_key || null,
      hasChatEnabled: isTruthy(rawData.field_is_chat_enabled_),
      showLandingPage: isTruthy(rawData.field_use_landing_page),
    };
  }

  static async getById (id: string): Promise<FullGameData> {
    let response: AxiosResponse;
    try {
      response = await axios.get(`/api/node/game/entity/id/${id}`);
    } catch (error) {
      logErrorEvent('Get Game by ID', false, error);
      throw error;
    }

    return GamesModel.transformFull(response.data[ 0 ]);
  }

  static async getBySlug (slug: string, credentials?: string): Promise<FullGameData> {
    const headers = {};

    if (credentials) {
      headers[ 'Cookie' ] = credentials;
    }

    let response: AxiosResponse;
    try {
      response = await axios.get(
        `/api/node/game/entity/alias?route=${slug}` +
        (credentials ? '&trackGameView=1' : ''),
        { headers }
      );
    } catch (error) {
      logErrorEvent('Get Game by Slug', false, error);
      throw error;
    }

    return GamesModel.transformFull(response.data[ 0 ]);
  }

  static async getViewCount (nid: string): Promise<number> {
    let response: AxiosResponse;
    try {
      response = await axios.get(`/api/game/play-count/${nid}`);
      return Number(response.data[ 0 ].totalcount);
    } catch (error) {
      logErrorEvent('Get Game View Count', false, error);
      throw error;
    }
  }

  static async getMockGames (limit: number, offset: number): Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await mockGet([ mockGameSchema ], limit, offset);
    } catch (error) {
      logErrorEvent('Get All Games', false, error);
      throw error;
    }

    return response.data.map((game: DrupalGameData) => GamesModel.transform(game));
  }

  static async getRelatedGames (
    gameId: string, 
    limit: number, 
    offset: number,
    mobileOnly?: boolean
  ): Promise<ExtendedGameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        `/sw/api/node/game/block/morelikethis/${gameId}?` +
        pagingQueryString(limit, offset) +
        (mobileOnly ? '&field_is_this_game_mobile=1' : '')
      );
    } catch (error) {
      logErrorEvent('Get Related Games', false, error);
      throw error;
    }

    return response.data.map((game: DrupalGameData) => GamesModel.transformExtended(game));
  }

  static async getRandomGame (endpoint: string) {
    let response: AxiosResponse;
    try {
      response = await axios.get(endpoint);
    } catch(error) {
      logErrorEvent('Get Random Game', false, error);
    }

    const transformed = response.data.map((game: DrupalGameData) => GamesModel.transformExtended(game));

    return transformed[ Math.floor(Math.random() * transformed.length) ];
  }

  static async get404PageGames (limit: number, offset: number): Promise<ExtendedGameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        '/api/404-recommended-games?' +
        pagingQueryString(limit, offset)
      );
    } catch (error) {
      logErrorEvent('Get 404 Page Games', false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transformExtended(game));
  }

  static async searchAllGames (
    limit: number, 
    offset: number, 
    criteria: Omit<SearchCriteria, 'method'>,
    mobileOnly?: boolean
  ): Promise<ExtendedGameData[]> {
    const sortBy = criteria.sortBy.replace('-', '');
    const sortOrder = criteria.sortBy.startsWith('-') ? 'DESC' : 'ASC';

    let response: AxiosResponse;
    try {
      if (criteria.genre) {
        response = await axios.get(
          '/api/games/search?' + 
          pagingQueryString(limit, offset) +
          `&tid=${criteria.genre}` +
          `&title=${criteria.query || ''}` +
          `&sort_by=${sortBy}` +
          `&sort_order=${sortOrder}` +
          (mobileOnly ? '&field_is_this_game_mobile=1' : '')
        );
      } else {
        response = await axios.get(
          '/api/games/search?' + 
          pagingQueryString(limit, offset) +
          `&title=${criteria.query || ''}` +
          `&sort_by=${sortBy}` +
          `&sort_order=${sortOrder}` +
          (mobileOnly ? '&field_is_this_game_mobile=1' : '')
        );
      }
    } catch (error) {
      logErrorEvent('Search All Games', false, error);
      throw error;
    }

    return (response.data.rows || response.data).map((game: DrupalGameData) => GamesModel.transformExtended(game));
  }

  static async getTopTags (gameId: string): Promise<any> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        `/sw/api/node/game/top-tags/${gameId}`
      );
    } catch (error) {
      logErrorEvent('Get Top Tags for Game', false, error);
      throw error;
    }

    return response.data;
  }

  static async getAdminFlags (gameId: string): Promise<AdminFlags> {
    let response: AxiosResponse;
    try {
      response = await axios.post(
        '/sw/api/game/all-admin-flagging-status',
        { nid: gameId }
      );
    } catch (error) {
      logErrorEvent('Get Admin Flags for Game', false, error);
      throw error;
    }

    return response.data.flagged_status as AdminFlags;
  }

  static async setAdminFlag (gameId: string, flag: keyof AdminFlags, value: number): Promise<void> {
    let response: AxiosResponse;
    try {
      response = await axios.post(
        value === 1
          ? '/sw/api/flag/node'
          : '/sw/api/unflag/node',
        { 
          nid: gameId,
          flag_id: flag,
        }
      );
    } catch (error) {
      logErrorEvent('Set Admin Flag for Game', false, error);
      throw error;
    }
  }

  static async dailySearchAllGames (
    limit: number, 
    offset: number, 
    criteria: Omit<DailySearchConfig, 'method'>, 
    sortOrder: 'ASC' | 'DESC' = 'ASC',
    mobileOnly?: boolean
  ): Promise<{ games: ExtendedGameData[], pager: PagerData }> {
    let response: AxiosResponse;
    const sortBy = criteria.sortBy.replace('-', '');
    const pagingParams = pagingQueryString(limit, offset);
    const queryParams = new URLSearchParams(pagingParams);

    if (!(
      criteria.genres.length == 1 && 
      criteria.genres[ 0 ] === '31' && 
      criteria.contentTypes.length === 1 &&
      criteria.contentTypes[ 0 ] === 'daily_game'
    )) {
      criteria.genres.filter((genre) => genre !== '0').forEach((genre) => {
        queryParams.append('tid[]', genre);
      });
    }
    criteria.contentTypes.forEach((contentType) => {
      queryParams.append('content_type[]', contentType);
    });
    if (criteria.contentTypes.includes('free_game')) {
      if (!criteria.contentTypes.includes('daily_game')) {
        queryParams.append('content_type[]', 'daily_game');
      }
    }
    if (criteria.query) queryParams.set('title', criteria.query || '');
    queryParams.set('sort_by', sortBy);
    queryParams.set('sort_order', sortOrder);

    if (mobileOnly) {
      queryParams.set('field_is_this_game_mobile', '1');
    }

    const baseUrl = '/api/games/search';
    const requestUrl = `${baseUrl}/?${queryParams.toString()}`;

    try {
      response = await axios.get(requestUrl);
    } catch (error) {
      logErrorEvent('Get All Online Games', false, error);
      throw error;
    }

    return {
      games: response.data.rows.map((game: DrupalGameData) => GamesModel.transformExtended(game)),
      pager: formatPagerData(response.data.pager || { total_items: 0 })
    };
  }

  static async getRecommendedDailyGames (currentGameId: string) {
    // https://dev.shockwave.com/api/daily_game_reccomendations/${currentGameId}

    let response: AxiosResponse;
    try {
      response = await axios.get(
        `/api/daily_game_reccomendations/${currentGameId}`
      );
    } catch (error) {
      logErrorEvent('Get Recommended Daily Games', false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transformExtended(game));
  }

  static async getDailyUpsell (): Promise<DailyGameUpsell> {
    // https://dev.shockwave.com/api/daily_game_upsell

    let response: AxiosResponse;
    try {
      response = await axios.get('/api/daily_game_upsell');
    } catch (error) {
      logErrorEvent('Get Daily Game Upsell', false, error);
      throw error;
    }

    return response.data[ 0 ];
  }
}
