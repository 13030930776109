import { DailyGameFields } from '@/lib/drupal/models/Games';
import { dateToDailyFields } from '@/lib/hooks/useDailyArchive';
import axios from '@/lib/swag/api/axiosInstance';
import { getZonedDate } from '@/lib/util/zonedTime';
import { AxiosResponse } from 'axios';
import { logErrorEvent } from 'react-commons';
import { isSameDay, addDays, format, addWeeks, isSameWeek, subWeeks } from 'date-fns';

export const LEADERBOARD_PERIOD = {
  daily: 'Daily',
  weekly: 'Weekly',
  alltime: 'All Time',
};

export type LeaderboardPeriod = keyof typeof LEADERBOARD_PERIOD;

interface SwagLeaderboardData {
  level_key: string
  value: string
  date_created: string
  screen_name: string
  avatarUrl: string
};

export interface LeaderboardData {
  displayName: string
  avatarUrl: string
  dateCreated: string
  value: string
};

interface SwagGameModeData {
  game: string
  name: string
  level_key: string
  value_type: 'time' | 'score'
  value_name: string
  reverse: boolean
  order: number
};

export interface GameModeData {
  key: string
  label: string
  reverse: boolean
  columnType: SwagGameModeData[ 'value_type' ]
  columnLabel: string
};

interface SwagUserBestData {
  dailyBest?: {
    value: string
  },
  scorePosition?: {
    value: number | '-'
  },
  totalScores?: {
    value: number | '-'
  }
};

export interface UserBestData {
  dailyBest: string | null
  scorePosition: number
  totalScores: number
};

export const ARCHIVE_STATUS = {
  notStarted: 'notStarted',
  inProgress: 'inProgress',
  started: 'started',
  complete: 'complete'
};

export type ArchiveStatus = keyof typeof ARCHIVE_STATUS;

export interface ArchiveCompletionData {
  day: number
  state?: ArchiveStatus
}

export interface DailyGameStreakData {
  gameId: string
  gameKeyword: string
  streak: number
  maxStreak: number
}

export interface SwagUser {
  _id: string
  memberName: string
  token?: string
}

export default class SwagModel {
  static transformLeaderboard (rawData: SwagLeaderboardData): LeaderboardData {
    return {
      displayName: rawData.screen_name,
      avatarUrl: rawData.avatarUrl,
      dateCreated:rawData.date_created,
      value: rawData.value,
    };
  }

  static transformGameMode (rawData: SwagGameModeData): GameModeData {
    return {
      key: rawData.level_key,
      label: rawData.name,
      reverse: rawData.reverse,
      columnType: rawData.value_type,
      columnLabel: rawData.value_name,
    };
  }

  static transformUserBest (rawData: SwagUserBestData): UserBestData {
    return {
      dailyBest: rawData.dailyBest?.value === '-' ? null : rawData.dailyBest?.value,
      scorePosition: rawData.scorePosition?.value === '-' ? 0 : rawData.scorePosition?.value,
      totalScores: rawData.totalScores?.value === '-' ? 0 : rawData.totalScores?.value,
    };
  }

  static async getGameId (keyword: string): Promise<string> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'get',
        url: `game?keyword=${keyword}&keywordtype=shockwave`,
      });
    } catch (error) {
      logErrorEvent('Get SWAG Game ID', false, error);
      throw error;
    }

    return response.data.game;
  }

  static async getSwagUser (): Promise<SwagUser> {
    let user;
    try {
      const response = await axios.request({
        method: 'get',
        url: process.env.NEXT_PUBLIC_SWAG_API_URL + '/user'
      });
      user = response.data;
    } catch {
      throw new Error('Failed to get user from API');
    }

    return user;
  }

  static async getGameModes (gameId: string): Promise<GameModeData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'get',
        url: `/score/categories?game=${gameId}`,
      });
    } catch (error) {
      logErrorEvent('Get SWAG Game Modes', false, error);
      throw error;
    }

    return response.data
      .sort((a: SwagGameModeData, b: SwagGameModeData) => a.order - b.order)
      .map((item: SwagGameModeData) => SwagModel.transformGameMode(item));
  }

  static async getDays (gameId: string, columnType: SwagGameModeData[ 'value_type' ]): Promise<GameModeData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'get',
        url: `/days?game=${gameId}&limit=30`,
      });
    } catch (error) {
      logErrorEvent('Get SWAG Days', false, error);
      throw error;
    }

    return response.data.map((date: string) => ({
      key: date,
      label: date,
      reverse: false,
      columnType,
      columnLabel: '',
    }));
  }

  static async getScores (gameId: string, gameMode: string, period: LeaderboardPeriod): Promise<LeaderboardData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'get',
        url: `/scores?game=${gameId}&type=standard&level_key=${gameMode}&period=${period}`,
      });
    } catch (error) {
      logErrorEvent('Get SWAG Scores', false, error);
      throw error;
    }

    return response.data
      .map((item: SwagLeaderboardData) => SwagModel.transformLeaderboard(item));
  }

  static async getDailyScores (gameId: string, day: string, levelKey: string, period: LeaderboardPeriod): Promise<LeaderboardData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'get',
        url: `/scores?game=${gameId}&day=${day}&type=daily&level_key=${levelKey}&period=${period}`,
      });
    } catch (error) {
      logErrorEvent('Get SWAG Daily Scores', false, error);
      throw error;
    }

    return response.data
      .map((item: SwagLeaderboardData) => SwagModel.transformLeaderboard(item));
  }

  static async getUserScores (gameId: string, gameMode: string, period: LeaderboardPeriod): Promise<LeaderboardData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'get',
        url: `/userbest?game=${gameId}&type=standard&level_key=${gameMode}&period=${period}`,
      });
    } catch (error) {
      logErrorEvent('Get SWAG User Scores', false, error);
      throw error;
    }

    return response.data
      .map((item: SwagLeaderboardData) => SwagModel.transformLeaderboard(item));
  }

  static async getUserBest (gameId: string, gameMode: string, period: LeaderboardPeriod): Promise<UserBestData> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'get',
        url: `/scores/context?game=${gameId}&type=standard&level_key=${gameMode}&period=${period}`,
      });
    } catch (error) {
      logErrorEvent('Get SWAG User Best', false, error);
      throw error;
    }

    return SwagModel.transformUserBest(response.data);
  }

  static async getUserBestDaily (gameId: string, day: string, gameMode: string): Promise<UserBestData> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'get',
        url: `/scores/context?game=${gameId}&day=${day}&type=daily&level_key=${gameMode}&period=alltime`,
      });
    } catch (error) {
      logErrorEvent('Get SWAG User Best', false, error);
      throw error;
    }

    return SwagModel.transformUserBest(response.data);
  }

  static async getTokenBalance () {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'get',
        url: '/tokenbalance',
      });
    } catch (error) {
      logErrorEvent('Get SWAG Token Balance', false, error);
      throw error;
    }

    return response.data.total_tokens;
  }

  // Generates a URL for fetching a user's archive progress between two dates.
  static getArchiveProgressByDateRangeKey (gameId: string, userSwagId?: string, start?: DailyGameFields, end?: DailyGameFields): string {

    const urlParams = [ `game=${gameId}` ];

    if(start && end) {
      const formatDate = (date: DailyGameFields) => `${date.day}-${date.month}-${date.year}`;

      urlParams.push(`start=${formatDate(start)}`);
      urlParams.push(`end=${formatDate(end)}`);
    }

    if(userSwagId) {
      urlParams.push(`entityTarget=${userSwagId}`);
    }

    return `/dailygamearchive?${urlParams.join('&')}`;

  }

  // Generates a URL for fetching a user's archive progress for a specific month and year.
  static getArchiveProgressByMonthKey (gameId: string, month: Number | String, year: Number | String, userSwagId?: string): string {
    const urlParams = [ `game=${gameId}`, `month=${month}`, `year=${year}` ];

    if(userSwagId) {
      urlParams.push(`entityTarget=${userSwagId}`);
    }

    return `/dailygamearchive?${urlParams.join('&')}`;
  }

  // Generates a URL for fetching the most recent 15 puzzles that are either incomplete or not started. 
  static getRecentArchiveProgressKey (gameId: string, userSwagId?: string): string {
    
    const urlParams = [ `game=${gameId}` ];

    if(userSwagId) {
      urlParams.push(`entityTarget=${userSwagId}`);
    }

    return `/dailygameprogress?${urlParams.join('&')}`;

  }

  // Fetches and formats archive progress from a compatible URL. Intended to be used with useSWR hook.
  static async archiveProgressFetcher (url: string): Promise<Record<string, ArchiveStatus>> {
    let response: AxiosResponse;

    try {
      response = await axios.request({
        method: 'get',
        url: url,
      });
    } catch (error) {
      logErrorEvent(`Get SWAG Archive Progress ${url}`, false, error);
      throw error;
    }

    const result = response.data.reduce((accum, d) => {
      return {
        ...accum,
        [ d.day ]: d.state ?? ARCHIVE_STATUS.notStarted
      };
    }, {});

    return result;
  }

  static getUserStreakKey (gameKeywords: string[], gameIds: string[], userId?: string): string {

    const queryParams = [];

    if(gameKeywords.length > 0) {
      queryParams.push(...gameKeywords.map((gameKeyword) => `keywords[]=${gameKeyword}`));
    }

    if(gameIds.length > 0) {
      queryParams.push(...gameIds.map((gameId) => `games[]=${gameId}`));
    }

    if(userId) {
      queryParams.push(`memberIdTarget=${userId}`);
    }

    return `/dailygamestreaks?${queryParams.join('&')}`;

  }

  static async userStreakFetcher (url: string): Promise<Record<string, DailyGameStreakData>> {
    let response: AxiosResponse;

    try {
      response = await axios.request({
        method: 'get',
        url,
      });
    } catch (error) {
      logErrorEvent('Get SWAG Daily Game streak data', false, error);
      throw error;
    }

    const data = response.data?.reduce((accum, data) => {

      const formatted = {
        gameId: data.game._id,
        gameKeyword: data.game.keyword,
        streak: data.streak ?? 0,
        maxStreak: data.maxStreak ?? data.maxstreak ?? 0
      };
      
      return {
        ...accum,
        [ formatted.gameKeyword ]: formatted
      };

    }, {});

    return data;
  }
};
