export const formatMoneyString = (str: string): string => {

  if(!str || typeof str !== 'string' || str.length === 0) {
    return '';
  }

  const [ dollars, cents ] = str.split('.');

  if(!cents) {
    return `${dollars}.00`;
  } else if(cents.length === 1) {
    return `${dollars}.${cents}0`;
  } else {
    return str;
  }
};

export default formatMoneyString;